import React from "react";
import usePersistedState from "./usePersistedState";

export const FormContext = React.createContext();

export const FormDataProvider = ({ children }) => {
  const [clause, setClause] = usePersistedState("clause", false);
  const [issuer, setIssuer] = usePersistedState("issuer", "");
  const [isNewCuit, setIsNewCuit] = usePersistedState("isNewCuit", false);
  const [issueDate, setIssueDate] = usePersistedState("issueDate", null);
  const [paymentDate, setPaymentDate] = usePersistedState("paymentDate", null);
  const [amount, setAmount] = usePersistedState("amount", null);
  const [postalCode, setPostalCode] = usePersistedState("postalCode", {
    entityNumber: null,
    houseNumber: null,
    postalNumber: null,
    chequeDigit: null,
  });
  const [chequeCode, setChequeCode] = usePersistedState("chequeCode", {
    chequeNumber: null,
    chequeDigit: null,
  });
  const [accountCode, setAccountCode] = usePersistedState("accountCode", {
    accountCode: null,
    chequeDigit: null,
  });
  const [endorsements, setEndorsements] = usePersistedState("endorsements", 0);
  const [echeqNumber, setEcheqNumber] = usePersistedState("echeqNumber", null);
  const [caeNumber, setCaeNumber] = usePersistedState("caeNumber", null);
  const [offer, setOffer] = usePersistedState("offer", null);
  const [cardData, setCardData] = usePersistedState("cardData", null);
  const [instructionData, setInstructionData] = usePersistedState(
    "instructionData",
    null
  );
  const [cantErrors, setCantErrors] = usePersistedState("cantErrors", 0);
  const [errorsFields, setErrorsFields] = usePersistedState("errorsFields", {
    errorFieldsPostalCode: null,
    errorsFieldsChequeCode: null,
    errorsFieldsAccountCode: null,
  });

  const resetState = () => {
    setClause(false);
    setIssuer("");
    setIsNewCuit(false);
    setIssueDate(null);
    setPaymentDate(null);
    setAmount(null);
    setPostalCode({
      entityNumber: null,
      houseNumber: null,
      postalNumber: null,
      chequeDigit: null,
    });
    setChequeCode({
      chequeNumber: null,
      chequeDigit: null,
    });
    setAccountCode({
      accountCode: null,
      chequeDigit: null,
    });
    setEndorsements(0);
    setEcheqNumber(null);
    setCaeNumber(null);
    setOffer(null);
    setInstructionData(null);
    setCantErrors(0);
    setErrorsFields({
      errorFieldsPostalCode: null,
      errorsFieldsChequeCode: null,
      errorsFieldsAccountCode: null,
    });
  };

  const state = {
    clause,
    setClause,
    issuer,
    setIssuer,
    isNewCuit,
    setIsNewCuit,
    issueDate,
    setIssueDate,
    paymentDate,
    setPaymentDate,
    amount,
    setAmount,
    postalCode,
    setPostalCode,
    chequeCode,
    setChequeCode,
    accountCode,
    setAccountCode,
    endorsements,
    setEndorsements,
    echeqNumber,
    setEcheqNumber,
    caeNumber,
    setCaeNumber,
    offer,
    setOffer,
    cardData,
    setCardData,
    instructionData,
    setInstructionData,
    cantErrors,
    setCantErrors,
    errorsFields,
    setErrorsFields,
    resetState,
  };
  return <FormContext.Provider value={state}>{children}</FormContext.Provider>;
};
